import React, { ReactElement } from "react";
export default function IconSaveCombine(): ReactElement {
    return (
        <svg
            width="131"
            height="125"
            viewBox="0 0 131 125"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 124L115 48V124"
                fill="#C2D3E4"
                fillOpacity="0.5"
            />
            <path
                d="M64.6105 0.581818C63.8464 1.09244 56.8995 6.70391 24.9849 32.5991C21.4471 35.482 18.3165 38.1095 18.0631 38.4672C17.2486 39.4619 17.4012 40.7118 18.42 41.733C19.4891 42.8048 20.6339 42.8806 21.906 42.0136C22.3889 41.6812 32.4425 33.5698 44.2764 23.9771C56.085 14.411 65.8826 6.55232 66.0617 6.52705C66.2142 6.50179 77.2853 15.915 90.6712 27.4211L115.001 48.3378L115.026 83.9773V119.642L59.0365 119.642C-1.91549 119.642 2.00436 119.54 1.31722 121.045C0.757433 122.244 0.935185 123.188 1.87701 124.131L2.7419 124.999L60.5381 124.999C122.584 124.999 119.403 125.075 120.014 123.799C120.141 123.544 120.294 107.548 120.37 88.2099L120.498 53.1063L123.883 56.0144C126.937 58.6419 127.344 58.9225 128.235 58.9225C130.119 58.9225 131.493 57.009 130.831 55.3003C130.679 54.8921 127.447 51.9068 122.815 47.9269L115.027 41.1918V24.15V7.08289L114.162 6.2159L113.298 5.34891H98.8916C83.5717 5.34891 83.3674 5.37418 82.8076 6.54833C82.6803 6.80364 82.5277 8.3847 82.4508 10.0429L82.3234 13.0786L74.9427 6.72652C70.8703 3.20538 67.359 0.271996 67.1043 0.170937C66.2646 -0.158836 65.4754 -0.00592804 64.6105 0.581818ZM109.681 23.6181C109.681 36.0418 109.656 36.5019 109.224 36.1694C108.969 35.9913 104.032 31.7813 98.2801 26.8068L87.7953 17.7766V14.2302V10.7091H98.739H109.683V23.6181H109.681Z"
                fill="#004483"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M109.681 23.6181C109.681 36.0418 109.656 36.5019 109.224 36.1695C108.969 35.9913 104.032 31.7813 98.2801 26.8068L87.7953 17.7766V14.2302V10.7091H98.739L109.683 10.7091V23.6181H109.681Z"
                fill="#C2D3E4"
                fillOpacity="0.5"
            />
            <path
                d="M40.7134 53.9506C26.2583 55.7617 17.0709 63.0327 14.7548 74.487L14.3224 76.6558L12.5661 77.4975C10.1478 78.697 7.34888 81.5545 5.92421 84.335C3.63332 88.7231 2.28558 94.5394 2.20864 100.203L2.15823 103.341L2.99792 104.107C3.78719 104.821 3.93974 104.846 6.1789 104.846C8.92743 104.846 9.61457 104.566 10.2513 103.238C10.6585 102.447 10.6837 102.167 10.429 101.402C10.0987 100.356 9.07998 99.4886 8.2403 99.4886C7.70571 99.4886 7.62878 99.361 7.62878 98.494C7.62878 96.6829 8.62101 91.6818 9.38508 89.5396C10.7593 85.7897 12.5409 83.5704 15.2125 82.3457L16.8162 81.6064L50.7153 81.7088C81.4837 81.7859 84.8438 81.8112 86.9808 82.2194C96.4733 83.98 101.308 88.9292 102.403 98.0113L102.581 99.4913H101.588C100.341 99.4913 99.3996 100.205 98.9924 101.405C98.7377 102.171 98.7629 102.451 99.1702 103.241C99.8321 104.619 100.467 104.849 103.623 104.849C106.295 104.849 106.372 104.824 107.186 104.109L108.026 103.344L107.976 100.716C107.899 95.6138 106.271 90.1034 103.751 86.3017C102.224 84.0052 99.271 81.1995 97.057 79.9496C93.7235 78.0879 89.1165 76.8633 83.6698 76.4032L81.7106 76.225L81.2012 74.5415C80.9213 73.5974 80.132 71.6839 79.4449 70.2811C74.7397 60.7363 66.5963 55.0477 55.9072 53.8482C52.6241 53.4666 44.0469 53.5424 40.7134 53.9506ZM58.1464 59.7164C60.818 60.2522 63.7204 61.3745 66.1121 62.804C67.944 63.901 71.0242 66.7839 72.4237 68.7732C73.4676 70.2266 75.5542 74.3088 75.9615 75.6864L76.114 76.2729L61.4799 76.2729H46.8206L46.8206 67.7772C46.8206 63.0832 46.8976 59.1791 47.0249 59.0528C47.3552 58.6964 55.4735 59.1805 58.1464 59.7164ZM41.4774 67.8543L41.4774 76.2729H30.661H19.8446L19.9972 75.5588C20.48 73.3647 20.7347 72.6254 21.6766 70.66C22.5414 68.849 23.076 68.1083 25.0366 66.1961C28.3449 62.905 31.908 61.1445 37.608 59.9464C38.8297 59.6911 40.204 59.461 40.6616 59.461L41.4761 59.4358V67.8543H41.4774Z"
                fill="#004483"
            />
            <path
                d="M23.0004 91.0448C19.9972 92.1419 17.5803 94.3612 16.1039 97.3717C15.162 99.2852 15.1368 99.4129 15.1368 102.168C15.1368 104.923 15.162 105.051 16.0787 106.913C17.3256 109.412 19.3618 111.454 21.8808 112.704C23.6875 113.596 23.8918 113.648 26.3339 113.648C28.7004 113.648 29.0055 113.571 30.6597 112.781C35.3171 110.511 38.3203 105.101 37.4554 100.51C36.7431 96.6829 34.045 93.1365 30.6093 91.5302C29.1329 90.8161 28.5479 90.6885 26.5886 90.638C24.8589 90.5848 23.9926 90.6872 23.0004 91.0448ZM28.5227 96.3C30.8892 97.1417 32.1613 99.2081 32.1361 102.168C32.1109 104.132 31.831 105.051 30.8892 106.326C29.8452 107.704 28.4205 108.29 26.2066 108.265C24.0696 108.265 23.2286 107.932 21.9312 106.607C19.513 104.132 19.9972 98.6722 22.7709 96.9382C24.502 95.8665 26.6138 95.6364 28.5227 96.3Z"
                fill="#004483"
            />
            <path
                d="M80.7714 91.0183C75.4017 92.9317 71.8134 98.6722 72.7553 103.8C74.0526 110.815 80.8735 115.306 87.1081 113.239C91.7655 111.709 95.2264 106.99 95.2264 102.168C95.2264 97.6776 92.58 93.5966 88.3047 91.5302C86.9052 90.8414 86.3202 90.7137 84.3596 90.638C82.6285 90.5595 81.7636 90.6619 80.7714 91.0183ZM87.0564 96.7082C89.0674 97.8305 89.9827 99.8969 89.7797 102.857C89.602 105.076 88.7358 106.607 87.0816 107.576C86.0642 108.188 85.6052 108.29 83.9762 108.29C81.0752 108.265 79.2167 107.065 78.4036 104.616C78.0467 103.519 78.0467 100.943 78.4036 99.6416C78.7339 98.4422 80.465 96.6563 81.6867 96.2481C83.1127 95.7641 85.7591 95.9941 87.0564 96.7082Z"
                fill="#004483"
            />
            <path
                d="M42.3171 100.381C41.5026 101.197 41.427 101.376 41.553 102.37C41.6552 103.136 41.9351 103.645 42.4948 104.131L43.2576 104.845H55.2188H67.1799L67.9944 104.105C68.7067 103.467 68.8341 103.187 68.8341 102.167C68.8341 101.147 68.7067 100.866 67.9944 100.228L67.1799 99.4886H55.1936H43.2072L42.3171 100.381Z"
                fill="#004483"
            />
        </svg>
    );
    return (
        <svg
            width="276"
            height="276"
            viewBox="0 0 276 276"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <filter
                    x="-9.6%"
                    y="-6%"
                    width="119.2%"
                    height="119.2%"
                    filterUnits="objectBoundingBox"
                    id="a"
                >
                    <feOffset
                        dy="9"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    />
                    <feGaussianBlur
                        stdDeviation="6.5"
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                    />
                    <feColorMatrix
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.129186243 0"
                        in="shadowBlurOuter1"
                    />
                </filter>
                <circle id="b" cx="125" cy="125" r="125" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(13 4)">
                    <use fill="#000" filter="url(#a)" xlinkHref="#b" />
                    <use fill="#FFF" xlinkHref="#b" />
                </g>
                <path fillOpacity=".5" fill="#C2D3E4" d="m73 191 115-76v76" />
                <path
                    d="M137.61 67.582c-.764.51-7.71 6.122-39.625 32.017-3.538 2.883-6.669 5.51-6.922 5.868-.814.995-.662 2.245.357 3.266 1.07 1.072 2.214 1.148 3.486.28.483-.332 10.537-8.443 22.37-18.036 11.809-9.566 21.607-17.425 21.786-17.45.152-.025 11.223 9.388 24.61 20.894L188 115.338l.025 35.64v35.664h-55.99c-60.951 0-57.032-.102-57.719 1.403-.56 1.2-.382 2.143.56 3.086l.865.868h57.796c62.046 0 58.865.076 59.476-1.2.127-.255.28-16.25.356-35.59l.128-35.103 3.385 2.908c3.054 2.628 3.461 2.909 4.352 2.909 1.884 0 3.258-1.914 2.596-3.623-.152-.408-3.384-3.393-8.016-7.373l-7.788-6.735V74.083l-.865-.867-.864-.867h-14.406c-15.32 0-15.525.025-16.084 1.2-.128.255-.28 1.836-.357 3.494l-.128 3.036-7.38-6.352c-4.073-3.522-7.584-6.455-7.839-6.556-.84-.33-1.629-.177-2.494.41zm45.071 23.036c0 12.424-.025 12.884-.457 12.551-.255-.178-5.192-4.388-10.944-9.362l-10.485-9.03V77.71h21.888v12.908h-.002z"
                    fill="#004483"
                    fillRule="nonzero"
                />
                <path
                    d="M182.681 90.618c0 12.424-.025 12.884-.457 12.551-.255-.178-5.192-4.388-10.944-9.362l-10.485-9.03V77.71h21.888v12.908h-.002z"
                    fillOpacity=".5"
                    fill="#C2D3E4"
                />
                <path
                    d="M113.713 120.95c-14.455 1.812-23.642 9.083-25.958 20.537l-.433 2.169-1.756.842c-2.418 1.199-5.217 4.057-6.642 6.837-2.29 4.388-3.638 10.204-3.715 15.868l-.05 3.138.839.766c.79.714.942.74 3.18.74 2.75 0 3.437-.281 4.073-1.609.408-.79.433-1.071.178-1.836-.33-1.046-1.349-1.913-2.189-1.913-.534 0-.611-.128-.611-.995 0-1.811.992-6.812 1.756-8.954 1.374-3.75 3.156-5.97 5.827-7.194l1.604-.74 33.9.103c30.768.077 34.128.102 36.265.51 9.492 1.761 14.327 6.71 15.422 15.792l.178 1.48h-.993c-1.247 0-2.188.714-2.596 1.914-.254.766-.23 1.046.178 1.836.662 1.378 1.297 1.608 4.453 1.608 2.672 0 2.749-.025 3.563-.74l.84-.765-.05-2.628c-.077-5.102-1.705-10.613-4.225-14.414-1.527-2.297-4.48-5.102-6.694-6.352-3.334-1.862-7.94-3.087-13.387-3.547l-1.96-.178-.509-1.683c-.28-.945-1.069-2.858-1.756-4.26-4.705-9.546-12.849-15.234-23.538-16.434-3.283-.381-11.86-.306-15.194.103zm17.433 5.766c2.672.536 5.574 1.659 7.966 3.088 1.832 1.097 4.912 3.98 6.312 5.97 1.044 1.453 3.13 5.535 3.537 6.912l.153.587H119.82v-8.496c0-4.694.078-8.598.205-8.724.33-.357 8.448.127 11.121.663zm-16.669 8.138v8.419H92.845l.152-.714c.483-2.194.738-2.934 1.68-4.899.864-1.811 1.399-2.552 3.36-4.464 3.308-3.29 6.871-5.052 12.571-6.25 1.222-.255 2.596-.485 3.054-.485l.814-.025v8.418h.001z"
                    fill="#004483"
                    fillRule="nonzero"
                />
                <path
                    d="M96 158.045c-3.003 1.097-5.42 3.316-6.896 6.327-.942 1.913-.967 2.04-.967 4.796 0 2.755.025 2.883.942 4.745a12.895 12.895 0 0 0 5.802 5.79c1.807.893 2.01.945 4.453.945 2.366 0 2.672-.077 4.326-.867 4.657-2.27 7.66-7.68 6.795-12.271-.712-3.827-3.41-7.373-6.846-8.98-1.476-.714-2.061-.842-4.02-.892-1.73-.053-2.596.05-3.589.407zm5.523 5.255c2.366.842 3.638 2.908 3.613 5.868-.025 1.964-.305 2.883-1.247 4.158-1.044 1.378-2.468 1.964-4.682 1.939-2.137 0-2.978-.333-4.276-1.658-2.418-2.475-1.934-7.935.84-9.669a6.727 6.727 0 0 1 5.752-.638zM153.771 158.018c-5.37 1.914-8.958 7.654-8.016 12.782 1.298 7.015 8.119 11.506 14.353 9.44 4.658-1.531 8.118-6.25 8.118-11.072 0-4.49-2.646-8.571-6.921-10.638-1.4-.689-1.985-.816-3.945-.892-1.731-.079-2.596.024-3.589.38zm6.285 5.69c2.011 1.122 2.927 3.189 2.724 6.149-.178 2.22-1.044 3.75-2.698 4.72-1.018.61-1.477.713-3.106.713-2.9-.025-4.76-1.225-5.572-3.674-.357-1.097-.357-3.673 0-4.974.33-1.2 2.061-2.986 3.283-3.394 1.426-.484 4.072-.254 5.37.46zM115.317 167.38c-.814.817-.89.996-.764 1.99.102.766.382 1.275.942 1.76l.763.715h23.922l.814-.74c.713-.638.84-.918.84-1.938s-.127-1.3-.84-1.939l-.814-.74h-23.973l-.89.893z"
                    fill="#004483"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    );
}
