import React, { ReactElement, ReactNode } from "react";
import IconFind from "../icons/find";
import IconSaveCombine from "../icons/save_combine";
import IconSaveMultiple from "../icons/save_multiple";
import style from "./style.module.scss";
const whyUsBlocks = [
    {
        id: 1,
        icon: <IconFind />,
        iconName: "IconFind",
        text: "Discover the  <span class='font-black'> top insurance</span> companies and compare rates.",
    },
    {
        id: 2,
        icon: <IconSaveCombine />,
        iconName: "IconSaveCombine",
        text: "Maximize your <span class='font-black'>savings</span> by bundling home and auto insurance.",
    },

    {
        id: 3,
        icon: <IconSaveMultiple />,
        iconName: "IconSaveMultiple",
        text: "Enjoy <span class='font-black'>discounts</span> for multiple cars and drivers.",
    },
];
interface BlockProps {
    id: number;
    icon: ReactNode;
    iconName: string;
    text: string;
}
export function WhyUsBlock({ block }: { block: BlockProps }): ReactElement {
    return (
        <div className={`${style.block} flex flex-col  items-center mb-8`}>
            <div
                className={`${style.blockImg} ${
                    style[`block${block.iconName}Img`]
                } flex justify-center items-center font-bold  text-xl mr-3 p-2 mb-0 md:mb-5`}
            >
                {block.icon}
            </div>
            <div className="flex text-xl items-center w-full">
                <div className={`${style.numberBullet} font-black`}>
                    {block.id}
                </div>

                <div
                    className={`${style.txtDesc} text-left`}
                    dangerouslySetInnerHTML={{
                        __html: block.text,
                    }}
                />
            </div>
        </div>
    );
}

export default function WhyToInsure(): ReactElement {
    return (
        <div className={`py-10`}>
            <div className={`${style.center} py-10`}>
                <div>
                    <div
                        className={`${style.line} font-normal text-center mb-4 md:mb-14`}
                    >
                        Why Choose{" "}
                        <span className={`${style.domainName} font-bold`}>
                            InsureYourCar.com?
                        </span>
                    </div>
                    <div
                        className={`${style.blockRow} text-center flex flex-row flex-wrap text-center justify-center md:justify-between`}
                    >
                        {whyUsBlocks.map((block) => (
                            <WhyUsBlock key={block.id} block={block} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
